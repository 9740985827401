var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v("Detalle del proceso N° "+_vm._s(_vm.bcProcId)+" "),_c('br'),_vm._v(" Fórmula original: "+_vm._s(_vm.formulaOriginal))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.desdeArchivo ? _vm.headersDesdeArchivo: _vm.headers,"items":_vm.itemsDetalleProcMovMasivo,"loading":_vm.isLoading,"expanded":_vm.expanded,"item-key":"itemId"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Mensaje:")]),_vm._v(" "+_vm._s(item.mensaje)+" ")])]}},{key:"item.numero",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensaje.length > 0 ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.numero))])]}},{key:"item.tipoYDoc",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensaje.length > 0 ? 'color:red' : '')},[_vm._v(_vm._s(item.tipoDoc)+" - "+_vm._s(item.nroDoc)+" ")])]}},{key:"item.benNombre",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensaje.length > 0 ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.benNombre)+" ")])]}},{key:"item.nroGrupo",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensaje.length > 0 ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.nroGrupo)+" ")])]}},{key:"item.tipoMovimiento",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensaje.length > 0 ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.tipoMovimiento)+" ")])]}},{key:"item.formulaUsada",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.mensaje.length > 0 ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.formulaUsada)+" ")])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"left":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"to-right py-4",attrs:{"color":"primary","small":"","outlined":"","disabled":_vm.itemsDetalleProcMovMasivo.length == 0},on:{"click":_vm.exportExcelModelo}},'v-btn',attrs,false),[_vm._v(" Exportar detalle ")])]}}])}),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModalVerDetalle}},[_vm._v(" Cerrar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }