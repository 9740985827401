<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 py-0"
      >
        <v-row>
          <v-col cols="12" md="10">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="12" md="2" align="end" v-if="canConfig" class="mt-4">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigMovMasivosPadrones()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a la configuración de movimientos masivos</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0 pt-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="py-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>

            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Filtros comunes -->
                  <!-- afiliado -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      ref="afiliado-autocomplete"
                      cache-items
                      v-model.trim="afiliadoSelected"
                      :items="afiliados"
                      :search-input.sync="afiliadosAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      :filter="customFilter"
                      outlined
                      autocomplete="not"
                      dense
                      return-object
                      clearable
                      label="Afiliado"
                      hint="Apellido, DNI o N° afiliado"
                      persistent-hint
                      append-icon
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Doc: {{ item.doc }} - Nro afiliado:
                            {{ item.numero }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="afiliadosLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Fecha desde -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="[
                            !(fechaDesdeSelected && fechaHastaSelected) ||
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                              'Formato incorrecto'
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          v-mask="'##/##/####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            !(fechaDesdeSelected && fechaHastaSelected) ||
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                              'Formato incorrecto'
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- periodo -->
                  <v-col cols="12" md="3" class="py-0">
                    <v-text-field
                      ref="periodo"
                      v-model="periodo"
                      label="Período"
                      outlined
                      dense
                      clearable
                      v-mask="'######'"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      :rules="rules.periodoYyyyMm"
                      hint="Formato AAAAMM"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="12" md="4" sm="6" class="py-0 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsMovMasivosPadrones"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            item-key="bcProcId"
            :expanded.sync="expanded"
            sort-by="bcProcId"
            :sort-desc="true"
          >
            <template v-slot:[`item.esAnula`]="{ item }">
              <v-icon v-if="item.esAnula" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="nuevoProcesoMovMasivo"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      verMovMasivoPadron(
                        item.bcProcId,
                        item.formulaOriginal,
                        item.desdeArchivo
                      )
                    "
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver detalle del proceso</span>
              </v-tooltip>

              <v-tooltip left v-if="canAnular">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    :disabled="item.esAnula"
                    @click="anularProcesoMovMasivo(item.bcProcId)"
                  >
                    {{ anuladoIcon }}
                  </v-icon>
                </template>
                <span>Anular proceso</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td v-if="!item.esAnula" :colspan="headers.length">
                <strong>Fórmula aplicada:</strong> {{ item.formulaOriginal }}
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card-actions> </v-card-actions>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalVerDetalle"
      v-model="openModalVerDetalle"
      max-width="80%"
      @keydown.esc="closeModalVerDetalle"
      persistent
    >
      <VerDetalleProcesoMovMasivo
        :bcProcId="bcProcId"
        :formulaOriginal="formulaOriginalProp"
        :desdeArchivo="desdeArchivo"
        @closeModalVerDetalle="closeModalVerDetalle"
      ></VerDetalleProcesoMovMasivo
    ></v-dialog>
    <DeleteDialog
      :titleProp="titleAnular"
      :textDelete="textAnular"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showAnularModal"
      @onDeleteItem="confirmAnular()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import VerDetalleProcesoMovMasivo from "@/components/modules/afiliaciones/afiliados/VerDetalleProcesoMovMasivo.vue";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConsultaMovMasivoPadrones",
  components: {
    PageHeader,
    DeleteDialog,
    FiltersSelected,
    Ayuda,
    VerDetalleProcesoMovMasivo
  },
  directives: { mask },
  mounted() {},
  data: vm => ({
    fechaDesdeSelected: vm.formatDate(
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
        .toISOString()
        .substr(0, 10)
    ),
    fechaHastaSelected: vm.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    singleExpand: true,
    singleSelect: false,
    title: enums.titles.MOVIMIENTO_MASIVO,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    seeIcon: enums.icons.SEE,
    searchIcon: enums.icons.SEARCH,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    anuladoIcon: enums.icons.CIRCLE_NONE,
    expanded: [],
    showAnularModal: false,
    titleAnular: "¿Desea anular el proceso?",
    textAnular: "Se anularán todos los movimientos grabados en el proceso.",
    periodo: "",
    showExpand: false,
    openModalVerDetalle: false,
    showHelp: false,
    optionCode: enums.webSiteOptions.MOVIMIENTOS_MASIVOS,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showIcon: true,
    rules: rules,
    settingsIcon: enums.icons.SETTINGS,
    canConfig: false,
    isLoading: false,
    formulaOriginalProp: null,
    desdeArchivo: null,
    itemsMovMasivosPadrones: [],
    search: "",
    headers: [
      {
        text: "N° de Proceso",
        value: "bcProcId",
        sortable: true
      },
      {
        text: "Tipo de proceso",
        value: "bcProcTipo",
        sortable: false,
        align: "left"
      },
      {
        text: "Usuario ejecución",
        value: "usuarioAlta",
        sortable: false,
        align: "left"
      },
      {
        text: "Fecha ejecución",
        value: "fechaProceso",
        sortable: false
      },
      {
        text: "Anulado",
        value: "esAnula",
        sortable: false,
        align: "center"
      },
      {
        text: "Usuario anulación",
        value: "usuarioAnula",
        sortable: false
      },
      {
        text: "Fecha anulación",
        value: "fechaAnula",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand", align: "end" }
    ],
    showFilters: true,
    filtersApplied: [],
    isFormValid: true,
    afiliadosAutocompleteInput: null,
    afiliadosLoading: false,
    afiliados: [],
    afiliadoSelected: null,
    menu1: null,
    menu2: null,
    fechaDesde: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .substr(0, 10),
    fechaHasta: new Date().toISOString().substr(0, 10),
    calendarIcon: enums.icons.CALENDAR,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    mSCloseBoxIcon: enums.icons.CLOSE_BOX,
    resultsExportExcel: [],
    excelIcon: enums.icons.EXPORT_EXCEL,
    allowedActions: null,
    canCreate: false,
    canAnular: false
  }),
  watch: {
    afiliadosAutocompleteInput(val) {
      if (val && val.length >= 3 && val.trimStart()) {
        this.getAfiliadosFilter();
      }
    }
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.setPermisos();
    this.applyFilters();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchAfiliadosByQuery: "aportes/fetchAfiliadosByQuery",
      fetchProcesosMovimientosMasivosByFilters:
        "afiliaciones/fetchProcesosMovimientosMasivosByFilters",
      anularProcesoMovimientoMasivo:
        "afiliaciones/anularProcesoMovimientoMasivo"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_PROC_MOV_MASIVO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.ANULAR_PROC_MOV_MASIVO:
            this.canAnular = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIGURAR_PROCESO_MOV_MASIVO:
            this.canConfig = true;
            break;
          default:
            break;
        }
      });
    },
    verMovMasivoPadron(bcProcId, formulaOriginal, desdeArchivo) {
      this.openModalVerDetalle = true;
      this.desdeArchivo = desdeArchivo;
      this.bcProcId = bcProcId;
      this.formulaOriginalProp = formulaOriginal;
    },
    closeModalVerDetalle() {
      this.openModalVerDetalle = false;
    },
    async applyFilters() {
      this.isLoading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const data = {
        benId: this.afiliadoSelected != null ? this.afiliadoSelected.id : -1,
        fechaDesde: this.fechaDesdeSelected
          ? this.parseDateToIso(this.fechaDesdeSelected)
          : null,
        fechaHasta: this.fechaHastaSelected
          ? this.parseDateToIso(this.fechaHastaSelected)
          : null,
        periodoProceso: this.periodo != null ? this.periodo : null
      };
      try {
        const resp = await this.fetchProcesosMovimientosMasivosByFilters(data);
        this.itemsMovMasivosPadrones = resp;
        let tieneFormula = this.itemsMovMasivosPadrones.filter(
          x => x.formulaOriginal.length > 0
        );
        this.expanded = tieneFormula;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    nuevoProcesoMovMasivo() {
      this.$router.push({ name: "NuevoMovMasivoPadrones" });
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.afiliadoSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "benId",
          label: "Afiliado",
          model: this.afiliadosAutocompleteInput
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
      if (this.periodo) {
        this.filtersApplied.splice(3, 1, {
          key: "periodoProceso",
          label: "Período",
          model: this.periodo
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    getAfiliadosFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.afiliados?.some(x => x.value == this.afiliadosAutocompleteInput)
        ) {
          return;
        } else {
          this.afiliadosLoading = true;
          const afiliados = await this.fetchAfiliadosByQuery({
            input: this.afiliadosAutocompleteInput
          });
          this.$refs["afiliado-autocomplete"].cachedItems = [];
          this.afiliados = afiliados;
          this.afiliadosLoading = false;
        }
      }, 2000);
    },
    customFilter(item, queryText, itemText) {
      return (
        item.value ||
        item.doc.indexOf(queryText) > -1 ||
        item.numero.indexOf(queryText) > -1
      );
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    goToConfigMovMasivosPadrones() {
      this.$router.push({
        name: "ConfigMovMasivosPadrones"
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    anularProcesoMovMasivo(id) {
      this.showAnularModal = true;
      this.idToAnular = id;
    },
    async confirmAnular() {
      const response = await this.anularProcesoMovimientoMasivo({
        bcProcId: this.idToAnular
      });
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Anulado con éxito." });
        this.showAnularModal = false;
      }
      this.applyFilters();
    }
  }
};
</script>

<style lang="scss" scoped>
.my-row {
  max-height: 80px;
}
.v-toolbar__content {
  height: 40px !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.icon-readonly {
  cursor: context-menu;
}
.my-tooltip {
  white-space: pre-line;
}
::v-deep label {
  margin-bottom: 0;
}
.no-upper-case {
  text-transform: none;
}
</style>
