<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle del proceso N° {{ bcProcId }} <br />
        Fórmula original: {{ formulaOriginal }}</v-card-title
      >
      <v-data-table
        :headers="desdeArchivo ? headersDesdeArchivo: headers"
        :items="itemsDetalleProcMovMasivo"
        class="elevation-1"
        :loading="isLoading"
        :expanded.sync="expanded"
        item-key="itemId"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Mensaje:</strong>
            {{ item.mensaje }}
          </td>
        </template>
        <template v-slot:[`item.numero`]="{ item }">
          <span :style="item.mensaje.length > 0 ? 'color:red' : ''">
            {{ item.numero }}</span
          >
        </template>
        <template v-slot:[`item.tipoYDoc`]="{ item }"
          ><span :style="item.mensaje.length > 0 ? 'color:red' : ''"
            >{{ item.tipoDoc }} - {{ item.nroDoc }}
          </span></template
        >
        <template v-slot:[`item.benNombre`]="{ item }">
          <span :style="item.mensaje.length > 0 ? 'color:red' : ''">
            {{ item.benNombre }}
          </span>
        </template>
        <template v-slot:[`item.nroGrupo`]="{ item }">
          <span :style="item.mensaje.length > 0 ? 'color:red' : ''">
            {{ item.nroGrupo }}
          </span>
        </template>
        <template v-slot:[`item.tipoMovimiento`]="{ item }">
          <span :style="item.mensaje.length > 0 ? 'color:red' : ''">
            {{ item.tipoMovimiento }}
          </span>
        </template>
        <template v-slot:[`item.formulaUsada`]="{ item }">
          <span :style="item.mensaje.length > 0 ? 'color:red' : ''">
            {{ item.formulaUsada }}
          </span>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip left max-width="40%">
          <template v-slot:activator="{ attrs }">
            <v-btn
              color="primary"
              class="to-right py-4"
              v-bind="attrs"
              small
              outlined
              :disabled="itemsDetalleProcMovMasivo.length == 0"
              @click="exportExcelModelo"
            >
              Exportar detalle
            </v-btn>
          </template>
        </v-tooltip>
        <v-btn outlined @click="closeModalVerDetalle"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import enums from "@/utils/enums/index";

export default {
  name: "VerDetalleProcesoMovMasivo",
  props: {
    bcProcId: {
      type: Number,
      required: false
    },
    formulaOriginal: {
      type: String
    },
    desdeArchivo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    isLoading: false,
    itemsDetalleProcMovMasivo: [],
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    expanded: [],
    headers: [
      {
        text: "N° de afiliado",
        align: "center",
        value: "numero",
        sortable: false
      },
      {
        text: "Tipo y N° de doc",
        align: "center",
        value: "tipoYDoc",
        sortable: false
      },
      {
        text: "Apellido/s y nombre/s",
        value: "benNombre",
        sortable: false,
        align: "start"
      },
      {
        text: "N° de grupo familiar",
        value: "nroGrupo",
        sortable: false,
        align: "center"
      },
      {
        text: "Parentesco",
        value: "parentesco",
        sortable: false
      },
      {
        text: "Tipo de movimiento",
        value: "tipoMovimiento",
        sortable: false
      },
      {
        text: "Fórmula valorizada",
        value: "formulaUsada",
        sortable: false
      }
    ],
    headersDesdeArchivo: [
      {
        text: "N° de afiliado",
        align: "center",
        value: "numero",
        sortable: false
      },
      {
        text: "Tipo y N° de doc",
        align: "center",
        value: "tipoYDoc",
        sortable: false
      },
      {
        text: "Apellido/s y nombre/s",
        value: "benNombre",
        sortable: false,
        align: "start"
      },
      {
        text: "N° de grupo familiar",
        value: "nroGrupo",
        sortable: false,
        align: "center"
      },
      {
        text: "Parentesco",
        value: "parentesco",
        sortable: false
      },
      {
        text: "Tipo de movimiento",
        value: "tipoMovimiento",
        sortable: false
      },
      {
        text: "Observaciones",
        value: "formulaUsada",
        sortable: false
      }
    ],
    allowedActions: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setDetalleProcMovMasivo();
  },

  methods: {
    ...mapActions({
      getMovimientosMasivoById: "afiliaciones/getMovimientosMasivoById",
      setAlert: "user/setAlert"
    }),
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async setDetalleProcMovMasivo() {
      this.isLoading = true;
      const data = await this.getMovimientosMasivoById(this.bcProcId);
      this.itemsDetalleProcMovMasivo = data;
      this.isLoading = false;
      let tieneMensaje = this.itemsDetalleProcMovMasivo.filter(
        x => x.mensaje.length > 0
      );
      this.expanded = tieneMensaje;
      if (this.itemsDetalleProcMovMasivo.length < 1) {
        this.setAlert({
          type: "warning",
          message: "No hay actualizaciones para mostrar"
        });
      }
    },
    closeModalVerDetalle() {
      this.$emit("closeModalVerDetalle");
    },
    cleanChar(str, char) {
      while (true) {
        var result_1 = str.replace(char, "");
        if (result_1 === str) {
          break;
        }
        str = result_1;
      }
      return str;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsDetalleProcMovMasivo.forEach(x =>
        result.push({
          ["N° de afiliado"]: this.cleanChar(x.numero),
          ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.nroDoc,
          ["Apellido/s y nombre/s"]: x.benNombre,
          ["N° de grupo familiar"]: x.nroGrupo,
          ["Parentesco"]: x.parentesco,
          ["Tipo de movimiento"]: x.tipoMovimiento,
          ["Fórmula"]: x.formulaUsada
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle del proceso del movimiento masivo"
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
