var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 py-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('PageHeader',{attrs:{"title":_vm.title}})],1),(_vm.canConfig)?_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"2","align":"end"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"to-right",attrs:{"icon":""},on:{"click":function($event){return _vm.goToConfigMovMasivosPadrones()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v(_vm._s(_vm.settingsIcon))])],1)]}}],null,false,2766408413)},[_c('span',[_vm._v("Acceder a la configuración de movimientos masivos")])])],1):_vm._e()],1),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-4 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pr-0 pl-0 pt-0",attrs:{"cols":"1","align-self":"center","align":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.toggleFiltersSelected($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-autocomplete',{ref:"afiliado-autocomplete",attrs:{"cache-items":"","items":_vm.afiliados,"search-input":_vm.afiliadosAutocompleteInput,"item-text":"value","item-value":"id","filter":_vm.customFilter,"outlined":"","autocomplete":"not","dense":"","return-object":"","clearable":"","label":"Afiliado","hint":"Apellido, DNI o N° afiliado","persistent-hint":"","append-icon":""},on:{"update:searchInput":function($event){_vm.afiliadosAutocompleteInput=$event},"update:search-input":function($event){_vm.afiliadosAutocompleteInput=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value))]),_c('v-list-item-subtitle',[_vm._v("Doc: "+_vm._s(item.doc)+" - Nro afiliado: "+_vm._s(item.numero)+" ")])],1)]}},{key:"append",fn:function(){return [(_vm.afiliadosLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.afiliadoSelected),callback:function ($$v) {_vm.afiliadoSelected=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"afiliadoSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"outlined":"","clearable":"","dense":"","hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","rules":[
                          !(_vm.fechaDesdeSelected && _vm.fechaHastaSelected) ||
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected)) ||
                            'Formato incorrecto'
                        ]},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","dense":"","clearable":"","rules":[
                          !(_vm.fechaDesdeSelected && _vm.fechaHastaSelected) ||
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected)) ||
                            'Formato incorrecto'
                        ]},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],ref:"periodo",attrs:{"label":"Período","outlined":"","dense":"","clearable":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.periodoYyyyMm,"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"py-0 text-right",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsMovMasivosPadrones,"search":_vm.search,"loading":_vm.isLoading,"item-key":"bcProcId","expanded":_vm.expanded,"sort-by":"bcProcId","sort-desc":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.esAnula",fn:function(ref){
                        var item = ref.item;
return [(item.esAnula)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":_vm.nuevoProcesoMovMasivo}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.verMovMasivoPadron(
                      item.bcProcId,
                      item.formulaOriginal,
                      item.desdeArchivo
                    )}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle del proceso")])]),(_vm.canAnular)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.esAnula},on:{"click":function($event){return _vm.anularProcesoMovMasivo(item.bcProcId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anuladoIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Anular proceso")])]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                    var headers = ref.headers;
                    var item = ref.item;
return [(!item.esAnula)?_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Fórmula aplicada:")]),_vm._v(" "+_vm._s(item.formulaOriginal)+" ")]):_vm._e()]}}],null,true)})],1),_c('v-card-actions')],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-5 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.openModalVerDetalle)?_c('v-dialog',{attrs:{"max-width":"80%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalVerDetalle($event)}},model:{value:(_vm.openModalVerDetalle),callback:function ($$v) {_vm.openModalVerDetalle=$$v},expression:"openModalVerDetalle"}},[_c('VerDetalleProcesoMovMasivo',{attrs:{"bcProcId":_vm.bcProcId,"formulaOriginal":_vm.formulaOriginalProp,"desdeArchivo":_vm.desdeArchivo},on:{"closeModalVerDetalle":_vm.closeModalVerDetalle}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleAnular,"textDelete":_vm.textAnular,"maxWidth":'35%',"confirmButtonText":'Anular',"openDelete":_vm.showAnularModal},on:{"update:openDelete":function($event){_vm.showAnularModal=$event},"update:open-delete":function($event){_vm.showAnularModal=$event},"onDeleteItem":function($event){return _vm.confirmAnular()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }